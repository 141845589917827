* {
  outline: none;
}

@font-face {
  font-family: 'IBMPlexSansKR-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Regular.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  cursor: default;
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1;
  user-select: none;
  font-family: 'IBMPlexSansKR-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

ul {
  padding-inline: 0;
  margin-block: 0;
  list-style: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

#root {
  --color-background: #ffffff !important;
  --color-gray300: #dcdee3 !important;
  --color-gray600: #868b94 !important;
  --color-gray900: #212124 !important;
}

@media (prefers-color-scheme: dark) {
  #root {
    --color-background: #212124 !important;
    --color-gray300: #43474f !important;
    --color-gray600: #868b94 !important;
    --color-gray900: #eaebee !important;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
